import React, { useEffect } from 'react';
import moment from 'moment';
import { formatDate } from '../../../common';
import { Link } from 'react-router-dom';
import { ROUTER } from '../../../constant/router';
import { useNews } from '../../../hooks/news';
import { Spin } from 'antd';
import { useSystem } from '../../../hooks/system';
import i18n from '../../../lib/Language';

const RecentNews = () => {
  const { isLoading, getAllNewss, newss } = useNews();
  const { createUserActionHistory } = useSystem();

  useEffect(() => {
    if (newss?.length === 0) {
      getAllNewss();
    }
  }, []);

  // INSERT VIEW COUNT NEWS
  const handleNewsClick = async (id, name) => {
    const data = {
      NEWS_ID: id,
      ACTION_KEY: 'R',
      NAME: 'News',
      DESC: name,
      NOTE: 'Read News',
    };

    await createUserActionHistory(data);
  };

  return (
    <div class="recent-post-widget widget col-sm-6 col-md-12 col-xs-12 sd380" style={{ marginBottom: 30 }}>
      <div class="title-widget">{i18n.t('FLLs.recentNews')}</div>
      {isLoading ? (
        <div style={{ minHeight: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin />
        </div>
      ) : (
        <div class="content-widget">
          {newss &&
            newss?.length &&
            newss?.slice(0, 5)?.map((item, index) => (
              <div class="media" key={index}>
                <div class="media-left">
                  <a
                    href="https://baodanang.vn/channel/5411/201907/gan-200-thi-sinh-tham-gia-cuoc-thi-robokids-2019-3229506/index.htm"
                    class="link"
                  >
                    <img src={item.IMAGE} alt="" class="media-image" />
                  </a>
                </div>
                <div class="media-right">
                  <div class="info">
                    <div class="date-created item">
                      {/* <a href="#"> */}
                      <span>{moment(item?.CREATED_DATE).format(formatDate.Type)}</span>
                      {/* </a> */}
                    </div>
                  </div>
                  {/* <a href="#" class="link comment"> */}
                  <div href="#" class="link comment">
                    <i class="fa fa-eye"></i>
                    <span>{item?.VIEW_COUNT}</span>
                  </div>
                  {/* </a> */}
                  <Link
                    to={`${ROUTER.PUBLIC.SHOW_NEWS_DETAIL.path}/${item?.id}`}
                    class="link title"
                    onClick={() => handleNewsClick(item?.id, item?.TITLE)}
                  >
                    {item.TITLE}
                  </Link>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default RecentNews;
