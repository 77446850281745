import { atom, selector } from "recoil";
import { selectOptions } from "../../common";

const coursesState = atom({
  key: "coursesState",
  default: [],
});

const courseOptionsState = selector({
  key: "courseOptionsState",
  get: ({ get }) => {
    const sourses = get(coursesState);
    if (sourses.length) {
      return selectOptions(sourses);
    }
    return [];
  },
});

export { coursesState, courseOptionsState };
