import React from 'react';
import { formatDate } from '../../../common';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ROUTER } from '../../../constant/router';
import { Badge } from 'antd';
import { useSystem } from '../../../hooks/system';
import i18n from '../../../lib/Language';

const EdugateLayout = ({ news }) => {
  const { createUserActionHistory } = useSystem();

  // INSERT VIEW COUNT NEWS
  const handleNewsClick = async (id, name) => {
    const data = {
      NEWS_ID: id,
      ACTION_KEY: 'R',
      NAME: 'News',
      DESC: name,
      NOTE: 'Read News',
    };

    await createUserActionHistory(data);
  };

  return (
    <Badge.Ribbon text={news?.HOT_NEWS ? 'Hot' : ''} color={news?.HOT_NEWS ? 'red' : 'transparent'}>
      <div class="edugate-layout-1">
        <div class="edugate-image">
          <img src={news?.IMAGE} alt="FPT" class="img-responsive" />
        </div>
        <div class="edugate-content">
          <Link
            to={`${ROUTER.PUBLIC.SHOW_NEWS_DETAIL.path}/${news?.id}`}
            class="title"
            onClick={() => handleNewsClick(news?.id, news?.TITLE)}
          >
            {news?.TITLE}
          </Link>
          <div class="info">
            <div class="author item">
              <a href="#">By {news.NEWS_BY}</a>
            </div>
            <div class="date-time item">
              <a href="#">{moment(news?.CREATED_DATE).format(formatDate.Type)}</a>
            </div>
          </div>
          <div class="info-more">
            <div class="view item">
              <i className="fa fa-eye"></i>
              <p>{news?.VIEW_COUNT}</p>
            </div>
            <div class="comment item">
              <i class="fa fa-comment"></i>
              <p>0</p>
            </div>
          </div>
          <div class="description">{news?.DESC}</div>
          <Link
            to={`${ROUTER.PUBLIC.SHOW_NEWS_DETAIL.path}/${news?.id}`}
            onClick={() => handleNewsClick(news?.id, news?.TITLE)}
          >
            <button class="btn btn-green">
              <span>{i18n.t('public.viewDetail')}</span>
            </button>
          </Link>
        </div>
      </div>
    </Badge.Ribbon>
  );
};

export default EdugateLayout;
