import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import newsApi from '../api/newsApi';
import { newsState } from '../recoil/atom/newsState';

export const useNews = () => {
  const [similarNews, setSimilarNews] = useState([]);
  const [news, setNews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [setError] = useState(undefined);
  const [newss, setNewss] = useRecoilState(newsState);

  const getAllNewss = async () => {
    setIsLoading(true);
    try {
      let res = await newsApi.getAllNewss();
      if (res.data) {
        setNewss(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // enqueueSnackbar("Request failed !", { variant: "error" });
    }
  };

  const getNews = async (id, callback) => {
    setIsLoading(true);
    try {
      let res = await newsApi.getNews(id);
      if (res.data) {
        setNews(res.data.elements.news);
        callback();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // enqueueSnackbar("Request failed !", { variant: "error" });
    }
  };

  const getNewsListByNewsId = async (id, callback) => {
    setIsLoading(true);
    try {
      let res = await newsApi.getNewsListByNewsId(id);
      if (res.data) {
        setSimilarNews(res.data?.elements?.newsList);
        callback();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // enqueueSnackbar("Request failed !", { variant: "error" });
    }
  };

  const createNews = async (data, callback) => {
    setIsLoading(true);
    try {
      let res = await newsApi.createNews(data);
      if (res.data) {
        getAllNewss();
        setIsLoading(false);
        callback();
        setNews(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const updateNews = async (data, id, callback) => {
    setIsLoading(true);
    try {
      let res = await newsApi.updateNews(data, id);
      if (res.data) {
        getAllNewss();
        setIsLoading(false);
        callback();
        setNews(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteNews = async (id) => {
    setIsLoading(true);
    try {
      let res = await newsApi.deleteNews(id);
      if (res.data) {
        getAllNewss();
        setError(undefined);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    newss,
    news,
    similarNews,
    getAllNewss,
    getNews,
    getNewsListByNewsId,
    createNews,
    updateNews,
    deleteNews,
    isLoading,
  };
};
