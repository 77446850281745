import { Badge, Spin } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ROUTER } from '../../../constant/router';
import { useNews } from '../../../hooks/news';
import moment from 'moment';
import { formatDate } from '../../../common';
import { useSystem } from '../../../hooks/system';
import i18n from '../../../lib/Language';

const Consulting = () => {
  const { isLoading, getAllNewss, newss } = useNews();
  const { createUserActionHistory } = useSystem();

  useEffect(() => {
    if (newss?.length === 0) {
      getAllNewss();
    }
  }, []);

  // SORT NEWS BY CREATED DATE AND INDEX
  const newsByLatest = useMemo(() => {
    const sortData = newss?.slice().sort((a, b) => {
      if (a.HOT_NEWS) return -1;
      if (!a.HOT_NEWS) return 1;

      if (a?.INDEX > b?.INDEX) return 1;
      if (a?.INDEX < b?.INDEX) return -1;
    });

    return sortData.slice(0, 4);
  }, [newss]);

  // INSERT VIEW COUNT NEWS
  const handleNewsClick = async (id, name) => {
    const data = {
      NEWS_ID: id,
      ACTION_KEY: 'R',
      NAME: 'News',
      DESC: name,
      NOTE: 'Read News',
    };

    await createUserActionHistory(data);
  };

  return (
    <div className="section section-padding latest-news">
      <div className="container">
        <div className="group-title-index">
          <h4 className="top-title">{i18n.t('homepage.newsForStem')}</h4>

          <h2 className="center-title">{i18n.t('homepage.knowledgeNews')}</h2>

          <div className="bottom-title">
            <i className="bottom-icon icon-a-01-01"></i>
          </div>
        </div>
        {isLoading ? (
          <div style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin />
          </div>
        ) : (
          <div className="latest-news-wrapper">
            {newsByLatest &&
              newsByLatest?.length &&
              newsByLatest.map((item, index) => (
                <Badge.Ribbon text={item?.HOT_NEWS ? 'Hot' : ''} color={item?.HOT_NEWS ? 'red' : 'transparent'}>
                  <div className="edugate-layout-1" key={index}>
                    <div className="edugate-image">
                      <img src={item.IMAGE} alt="" className="img-responsive" />
                    </div>
                    <div className="edugate-content">
                      <Link
                        to={`${ROUTER.PUBLIC.SHOW_NEWS_DETAIL.path}/${item?.id}`}
                        className="title"
                        onClick={() => handleNewsClick(item?.id, item?.TITLE)}
                      >
                        {item.TITLE}
                      </Link>
                      <div className="info">
                        <div className="author item">
                          <a href="#">By {item?.NEWS_BY}</a>
                        </div>
                        <div className="date-time item">
                          <a href="#">{moment(item?.CREATED_DATE).format(formatDate.Type)}</a>
                        </div>
                      </div>
                      <div className="info-more">
                        <div className="view item">
                          <i className="fa fa-eye"></i>

                          <p>{item?.VIEW_COUNT}</p>
                        </div>
                        {/* <div className="comment item">
                        <i className="fa fa-comment"></i>

                        <p>239</p>
                      </div> */}
                      </div>
                      <div className="description">{item.DESC}</div>
                      <Link
                        to={`${ROUTER.PUBLIC.SHOW_NEWS_DETAIL.path}/${item?.id}`}
                        onClick={() => handleNewsClick(item?.id, item?.TITLE)}
                      >
                        <button className="btn btn-green">
                          <span>{i18n.t('public.viewDetail')}</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Badge.Ribbon>
              ))}
            <Link to={ROUTER.PUBLIC.NEWS.path}>
              <button className="btn btn-green btn-latest-new">
                <span>
                  {i18n.t('public.showAll')}
                  <i className="fa fa-long-arrow-right"></i>
                </span>
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Consulting;
