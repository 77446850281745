import axiosApiInstance from "../utils/axiosClient";

const newsApi = {
  getAllNewss: () => {
    const path = `/news/getAll`;
    return axiosApiInstance.get(path);
  },
  getNews: (id) => {
    const path = `/news/getNews/${id}`;
    return axiosApiInstance.get(path);
  },
  getNewsListByNewsId: (id) => {
    const path = `/news/getNewsListByNewsId/${id}`;
    return axiosApiInstance.get(path);
  },
  createNews: (data) => {
    const path = `/news/createNews`;
    return axiosApiInstance.post(path, data);
  },
  updateNews: (data, id) => {
    const path = `/news/updateNews/${id}`;
    return axiosApiInstance.patch(path, data);
  },
  deleteNews: (id) => {
    const path = `/news/deleteNews/${id}`;
    return axiosApiInstance.delete(path);
  },
};

export default newsApi;
